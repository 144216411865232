<template>
  <div>
    <h5 class="pl-1 font-weight-bold my-2">CREW INCIDENT / BEHAVIOR</h5>
    <table class="table table-sm table-bordered small">
      <thead>
      <tr>
        <th class="align-middle text-center"  style="width: 7%">Behavior Type</th>
        <th class="align-middle text-center">Vessel</th>
        <th class="align-middle text-center">Port From</th>
        <th class="align-middle text-center">Port To</th>
        <th class="align-middle text-center">Date</th>
        <th class="align-middle text-center">Manning</th>
        <th class="align-middle text-center">Remarks</th>
        <th class="align-middle text-center">VDR Codes</th>
        <th class="align-middle text-center">Files</th>
        <th class="align-middle text-center">Action</th>
      </tr>
      </thead>
      <tbody>
        <template v-if="incidentBehavior">
          <tr v-for="behavior in incidentBehavior">
            <td class="text-center" :title="behavior.incidentType ? behavior.incidentType.toUpperCase() : ''">
              {{behavior.incidentCode ? behavior.incidentCode.toUpperCase() : ''}}
            </td>
            <td class="text-center" >{{behavior.vessel ? behavior.vessel.toUpperCase() : ''}}</td>
            <td class="text-center" >{{behavior.port ? behavior.port.toUpperCase() : ''}}</td>
            <td class="text-center" >{{behavior.toPort ? behavior.toPort.toUpperCase() : ''}}</td>
            <td class="text-center" >{{formatEliteDate(behavior.incident_date)}}</td>
            <td class="text-center" >{{behavior.manning ? behavior.manning.toUpperCase() : ''}}</td>
            <td>{{behavior.remarks ? behavior.remarks.toUpperCase() : ''}}</td>
            <td class="text-center"  style="font-weight: bold">{{behavior.vdrObservations ? behavior.vdrObservations.join(',') : ''}}</td>
            <td class="text-center">{{behavior.crewIncidentAttachments ? behavior.crewIncidentAttachments.length : '' }}</td>
            <td class="text-center">
              <button type="button"
                      class="btn btn-outline-secondary btn-xs"
                      data-target="#crewIncidentBehavior"
                      @click="getBehavior(behavior)"
                      data-toggle="modal">
                View
              </button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="modal fade" id="crewIncidentBehavior" tabindex="-1" aria-labelledby="crewIncidentBehavior" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h5 class="modal-title" id="crewIncidentBehaviorTitle">View Crew Incident / Behavior</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div class="row">
                <div class="col-4">
                  <table class="w-100">
                    <tr>
                      <td>Type:</td>
                      <td style="font-weight:bold">{{behavior.incidentType ? behavior.incidentType.toUpperCase() : ''}}</td>
                    </tr>
                    <tr>
                      <td>Vessel:</td>
                      <td style="font-weight:bold">{{behavior.vessel ? behavior.vessel.toUpperCase() : ''}}</td>
                    </tr>
                    <tr>
                      <td>At Sea:</td>
                      <td style="font-weight:bold">{{behavior.to_port_id ? 'YES' : 'NO'}}</td>
                    </tr>
                    <tr>
                      <td>Port From:</td>
                      <td style="font-weight:bold">{{behavior.port ? behavior.port.toUpperCase() : ''}}</td>
                    </tr>
                    <tr v-if="behavior.to_port_id">
                      <td>Port From:</td>
                      <td style="font-weight:bold">{{behavior.port ? behavior.port.toUpperCase() : ''}}</td>
                    </tr>
                    <tr>
                      <td>Date:</td>
                      <td style="font-weight:bold">{{formatEliteDate(behavior.incident_date)}}</td>
                    </tr>
                    <tr>
                      <td>Remarks:</td>
                      <td style="font-weight:bold">
                        <div class="pre-wrap-txt textarea-display-card">
                          {{behavior.remarks ? behavior.remarks :'N / A'}}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Manning:</td>
                      <td style="font-weight:bold">
                          {{behavior.manning ? behavior.manning.toUpperCase() :''}}
                      </td>
                    </tr>
                  </table>
                  <hr>
                  <table class="w-100" v-if="behavior.crewIncidentAttachments">
                    <template v-for="(attachment,index) in behavior.crewIncidentAttachments">
                      <tr>
                        <td class="text-md-left font-weight-bold">
                           Attachment # {{index + 1}}:
                        </td>
                        <td class="mb-2">
                          <button class="btn btn-outline-secondary btn-xs"
                                  type="button" style="cursor:pointer"
                                  :disabled="!attachment.fileExists"
                                  data-target="#crewBehaviorAttachment"
                                  data-toggle="modal"
                                  @click="viewAccidentIncidentAttachment(attachment.id)">
                            View Attachment
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Description:</td>
                        <td>
                          <div class="pre-wrap-txt textarea-display-card my-2 font-weight-bold" >
                            {{behavior.description ? attachment.description :'N / A'}}
                          </div>
                        </td>

                      </tr>
                    </template>

                  </table>
                </div>
                <div class="col-8">
                  <table class="table table-sm table-bordered small">
                    <thead>
                      <tr>
                        <th class="align-middle text-center">Code</th>
                        <th class="align-middle text-center">Observation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-if="behavior.vdrObservationCodes">
                          <tr v-for="vdrCode in behavior.vdrObservationCodes">
                            <td class="text-center font-weight-bold">{{vdrCode.code}}</td>
                            <td>{{vdrCode.observation ? vdrCode.observation.toUpperCase() : ''}}</td>
                          </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="crewBehaviorAttachment" tabindex="-1" aria-labelledby="crewBehaviorAttachment" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h5 class="modal-title" id="crewBiodataTitle">Attachment # {{1}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <iframe style="width: 100%; height: 80vh;" :src="incidentAttachment"></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "CrewBehavior",
  data(){
    return {
      behavior: {},
      incidentAttachment:'',
    }
  },
  async created() {
    const params={
      cms_id:this.$route.params.id
    };
    await this.getIncindentBehavior(params);
  },
  methods:{
    ...mapActions(['getIncindentBehavior']),
    formatEliteDate(date){
      return DateService.dateEliteFormat(date);
    },
    getBehavior(behavior){
      this.behavior=Object.assign({},behavior);
    },

    async viewAccidentIncidentAttachment(attachmentId){
      const params={
        incidentBehaviorFile:attachmentId,
      }

      var response=await CrewService.getCrewIncidentFileFromCms(params);

      if(response.file){
        this.incidentAttachment=FileService.base64FileToBlobUrl(response.file);
      }

    }

  },
  computed:{
    ...mapGetters(['incidentBehavior'])
  }
}
</script>

<style scoped>

</style>
